const pirateImageArray =[
    {"src": 'img/Rum.png', matched: false},
    {"src": 'img/Pirate.png', matched: false},
    {"src": 'img/Parrot.png', matched: false},
    {"src": 'img/Map.png', matched: false},
    {"src": 'img/Flag.png', matched: false},
    {"src": 'img/Swords.png', matched: false},
    {"src": 'img/Bomb.png', matched: false},
    {"src": 'img/Anchor.png', matched: false}
];

export default pirateImageArray;