const kitchenImageArray =[
    {"src": 'img/Bowl.png', matched: false},
    {"src": 'img/Knife.png', matched: false},
    {"src": 'img/Microwave.png', matched: false},
    {"src": 'img/Mixer.png', matched: false},
    {"src": 'img/Spatula.png', matched: false},
    {"src": 'img/Spoon.png', matched: false},
    {"src": 'img/Weigh.png', matched: false},
    {"src": 'img/Whipper.png', matched: false}
];

export default kitchenImageArray;