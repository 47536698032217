const beachImageArray = [
    {"src": 'img/Ball.png', matched: false},
    {"src": 'img/Beer.png', matched: false},
    {"src": 'img/Icecream.png', matched: false},
    {"src": 'img/Lifesaver.png', matched: false},
    {"src": 'img/melon.png', matched: false},
    {"src": 'img/PaperBoat.png', matched: false},
    {"src": 'img/Sun.png', matched: false},
    {"src": 'img/Bucket.png', matched: false}
];

export default beachImageArray;