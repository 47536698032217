const spaceImageArray = [
    {"src": 'img/commet-1.png', matched: false},
    {"src": 'img/dish-1.png', matched: false},
    {"src": 'img/earth-1.png', matched: false},
    {"src": 'img/rocket-1.png', matched: false},
    {"src": 'img/saturn-1.png', matched: false},
    {"src": 'img/telescope-1.png', matched: false},
    {"src": 'img/Orbit.png', matched: false},
    {"src": 'img/Dome.png', matched: false}
];

export default spaceImageArray;